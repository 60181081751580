import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPosts, setIsPost] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isCategories, setIsCategory] = useState(false);
  const [isLinks, setIsLinks] = useState(false);
  const [isFootball, setFootball] = useState(false);
  const [isAuthentications, setAuthentications] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Links") {
      setIsLinks(false);
    }
  }, [history, iscurrentState, isDashboard, isPosts, isLinks, isFootball]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "links",
      label: "Links",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isLinks,
      click: function (e) {
        e.preventDefault();
        setIsLinks(!isLinks);
        setIscurrentState("Links");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "links",
          label: "Links",
          link: "/links",
        },
      ],
    },

  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
