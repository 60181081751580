import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  Input,
  Button,
  Form,
  message,
  Space,
  Select,
  Modal,
  Upload,
  Switch, 
  Drawer,
  Tooltip,
  Table,
  Tag
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import {
  insertLink,
  getAllLink,
  updateLink,
  deleteLink,
} from "../../helpers/helper";
import toSlug from "../../common/function";
const { Option } = Select;
const getAllData = async (_prams) => {
  const params = _prams
    ? _prams
    : {
        pageIndex: 1,
        pageSize: 10,
        search: "",
      };
  const dataRes = await getAllLink(params);

  const data =
    dataRes?.data &&
    dataRes?.data.length > 0 &&
    dataRes?.data.map((item) => {
      return {
        id: item._id,
        name: item.name,
        url: item.url,
        link_backup: item.link_backup
      };
    });
  return dataRes?.data ? data : [];
};

const Tags = () => {
  document.title = "Management Web";

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [tagName, setTagName] = useState("");
  const [listWeb, setListWeb] = useState([]);
  const [cacheSchemas, setCacheSchemas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListWeb(dataRes);
    }
    fetchData();
  }, []);

  const handleRefreshCreate = async () => {
    form.resetFields();
  };
  const handleRefreshSearch = async () => {
    const dataRes = await getAllData();
    setListWeb(dataRes);
    formSearch.resetFields();
  };
  
  const onFinish = async (data) => {
    const dataReq = {
      name: data.name,
      url: data.url,
      link_backup: data.link_backup
    };

    if (!data.id) {
      //Save
      const dataRes = await insertLink(dataReq);
      dataRes.success
        ? message.success(`Lưu thành công! ${dataRes.message}`)
        : message.error(`Save Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateLink(data.id, dataReq);
      dataRes.success
        ? message.success(`Update Success! ${dataRes.message}`)
        : message.error(`Update Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    }

    form.resetFields();
    const dataRes = await getAllData();
    setListWeb(dataRes);
  };


  const handleChangeQuery = (value) => {
    const listWebName = value.map((item) => {
      const index = listWeb.findIndex((x) => x._id === item);
      if (index !== -1) {
        return listWeb[index].tagName;
      } else {
        return item;
      }
    });
    if (cacheSchemas.length !== 0) {
      cacheSchemas.map((item) => {
        if (item?.script?.articleSection) {
          item.script.articleSection = listWebName;
        }
      });
      setCacheSchemas(cacheSchemas);
    }
  };
  const onFinishFailed = () => {
    // message.error("Save failed!");
  };

  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData();
    setListWeb(dataRes);
  };



  const onEdit = (key) => {
    const dataEdit = listWeb.filter((item) => item.id === key);
    form.setFieldsValue({
      name: dataEdit[0].name,
      url: dataEdit[0].url,
      id: dataEdit[0].id,
      link_backup: dataEdit[0].link_backup
    });
    showDrawer();
    setDrawerTitle("Sửa Web");
  };
  const onClose = () => {
    setVisibleForm(false);
  };
  const showDrawer = () => {
    setVisibleForm(true);
  };
  const handleNewTag = () => {
    setDrawerTitle("Thêm Web");
    showDrawer();
    form.resetFields();
  };
  const onDelete = async (key) => {
    const dataRes = await deleteLink(key);
    dataRes.status === 1
      ? message.success(`Xóa thành công! ${dataRes.message}`)
      : message.error(`Xóa thất bại! ${dataRes.message}`);

    handleRefresh();
  };

  const columns = [
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Link",
      dataIndex: "url",
    },
      {
        title: "Link BackUp",
        dataIndex: "link_backup",
        render: (_, record) => {
          console.log(_, record)
          const listWebName = _?.map((item, index) => {
            return (
              <Tag color="default" key={index}>
                {item}
              </Tag>
            );
          });
        return listWebName;
      }
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listWeb.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.id)}
              />
            </Tooltip>
            {/* <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip> */}
          </Space>
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Web" pageTitle="Quản lí link web" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"60%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col sm={4} hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="name"
                        label="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          },
                          {
                            type: "name",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter name"
                          name="name"
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item
                        name="url"
                        label="url"
                        rules={[
                          {
                            required: true,
                            message: "Please input url!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter tag slug"
                          name="url"
                          allowClear={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                <Form.Item
                  name="link_backup"
                  label="link backup"
                >
                  <Select
                    placeholder="link backup"
                    allowClear
                    onChange={handleChangeQuery}
                    mode="tags"
                    name="link_backup"
                  >
                    {listWeb.length > 0 &&
                      listWeb.map((item) => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {item.tagName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
                  </Row>
                  <Col sm={12}>
          
              </Col>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>

                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => handleRefreshCreate()}
                      >
                        Làm mới trang
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={formSearch}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col sm={4} hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    {/* <Form.Item
                      name="textSearch"
                      label="Từ khóa"
                      rules={[
                        {
                          required: false,
                          message: "Please input text name!",
                        },
                        {
                          type: "textSearch",
                        },
                        {
                          type: "string",
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter tag name"
                        name="textSearch"
                        allowClear={true}
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                      />
                    </Form.Item> */}
                  </Col>
                </Row>
                <Form.Item>
                  <Space>
                    <Button type="primary" onClick={handleNewTag}>
                    Tạo mới
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefreshSearch()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          {/* datatable tags */}
          <div>
            <Table
              columns={columns}
              dataSource={listWeb}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tags;